// app/javascript/controllers/new_leasing_tabs_controller.js

import {Controller} from "stimulus"

export default class extends Controller
{
    static get targets()
    {
        return [""];
    }

    connect() {}

    // Leasing type tabs handling -> hide and show tabs
    tabSelected(e)
    {
        e.preventDefault();
        $('.leasing_selection').each(function() {
            $(this).removeClass("leasing_selection_active");
        });
        $(e.target).addClass('leasing_selection_active');
        if (e.target.id === "company") {
            $("#company_tab").fadeIn("slow");
        } else {
            $("#company_tab").fadeOut("slow");
            $('#company_name').val("");
            $('#company_businessid').val("");
        }
        $('#leasing_type_selector').val(e.target.id);
    }
}
