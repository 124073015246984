// app/javascript/controllers/dashboard_controller.js
import {Controller} from "stimulus";

export default class extends Controller {
    static targets = ["parentDiv", "agreed", "denied"];

    initialize() {
        this.checkCookie();
    }

    consentAgreed(){
        this.setCookie("rk-c-c", "afasdf-334-asdf-3ed-3rdf", 90);
        this.checkCookie();
    }

    consentDenied(){
        this.parentDivTarget.style.display = 'none';
    }

    setCookie(cname, cvalue, exdays) {
        let d = new Date();
        d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }
    getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return true;
            }
        }
        return false;
    }
    checkCookie() {
        let isNotified = this.getCookie("rk-c-c");
        if (isNotified) {
            this.parentDivTarget.style.display = 'none';
        }
    }
}
