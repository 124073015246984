// app/javascript/controllers/leasing_created_controller.js

import {Controller} from "stimulus"

export default class extends Controller
{
    static get targets()
    {
        return [""];
    }

    connect()
    {
        // When connected to dom and leasing is created -> redirect back to main page
        let url  = $('#leasing_created').attr('data-url');
        setTimeout(function() {
            window.location = url;
        },6000);
    }
}