// app/javascript/controllers/leasing_controller.js - Leasing calculator

import {Controller} from "stimulus"

export default class extends Controller
{
    static get targets()
    {
        return ["leasingTime", "leasingTotalPrice", "leasingHeaderTotalPrice"];
    }

    // Stimulus controller connected to dom
    connect()
    {
        this.initVariables();
    }

    // Set active state and store selected mileage
    setMileage(e)
    {
        e.preventDefault();
        $('.button.outline.mileage').each(function () {
            $(this).removeClass("active");
        });
        e.currentTarget.classList.add("active");
        this.selected_mileage = parseInt(e.currentTarget.dataset.mileage) * 1000;
        this.calculateLeasingPrice();
    }

    // Calculating for starting leasing price calculation
    calculateLeasingPrice()
    {
        let selected_mileage = this.selected_mileage;
        let months_selected = parseInt(this.slider.noUiSlider.get());
        let options_price = this.calculateOptions(months_selected, selected_mileage);
        let remaining_value = this.calculateRemainingValue(months_selected, selected_mileage);
        let leasing_price =  this.calculateMonthValue(months_selected, options_price, remaining_value);
        this.leasingTotalPriceTarget.innerHTML = leasing_price[1];
        this.leasingHeaderTotalPriceTarget.innerHTML = leasing_price[1];
        this.leasing_price_selection = leasing_price[1];
        this.leasing_month_selection = months_selected;

        if (this.debugg) {
            console.log("**************************************");
            console.log("vehicle_price: " + this.vehicle_price);
            console.log("selected mileage: " + selected_mileage);
            console.log("months selected: " + months_selected);
            console.log("extra options: " + options_price);
            console.log("remaining value: " + remaining_value);
            console.log("leasing price: " + leasing_price);
            console.log("**************************************");
        }
    }

    // Function for calculating leasing remaining value
    calculateRemainingValue(months, mileage)
    {
        let remaining_mileage_percent = (mileage / months) * 0.00040;
        let leasing_percent = 1.031;
        let first_month_time_percent = 15.0;
        let first_year_time_percent = 2.5;
        let rest_time_percent = 2.25;
        let vehicle_price = this.vehicle_price;

        if (this.debugg) {
            console.log("Remaining calculation");
            console.log("Vehicle price: " + vehicle_price);
            console.log("Mileage %: " + remaining_mileage_percent);
        }

        for (let month = 1; month <= months; month++) {
            if (month === 1) {
                vehicle_price = (vehicle_price * ((100 - ((first_month_time_percent + remaining_mileage_percent) / 2.0)) / 100.0));
            } else if (month > 1 && month < 13) {
                vehicle_price = (vehicle_price * ((100 - ((first_year_time_percent + remaining_mileage_percent) / 2.0)) / 100.0));
            } else if (month >= 13) {
                vehicle_price = (vehicle_price * ((100 - ((rest_time_percent + remaining_mileage_percent) / 2.0)) / 100.0));
            }
            if (this.debugg) {
                console.log("R value: " + vehicle_price + " month: " + month);
            }
        }
        if (this.debugg) {
            console.log("Final result: " + vehicle_price);
        }
        return vehicle_price;
    }

    // Calculate month value
    calculateMonthValue(months_selected,options_price,remaining_value)
    {
        let month_price = [0.00,0.00];
        let vehicle_price = this.vehicle_price - remaining_value;
        let alvValue = 1.24;
        vehicle_price += options_price;
        vehicle_price *= 1.031;
        month_price[0] = Math.round(vehicle_price / months_selected);
        month_price[1] = Math.round((vehicle_price / months_selected) * alvValue);
        if (this.debugg) {
            console.log("vehicle price + options: " + vehicle_price);
            console.log("vehicle remaining value: " + remaining_value);
        }
        return month_price;
    }

    // Calculate selected options
    calculateOptions(selected_months,selected_mileage)
    {
        //unit_type 0 is kk
        //unit_type 1 is km
        //unit_type 2 is fixed sum per leasing duration
        const leasing_controller = this;
        leasing_controller.leasing_options = [];
        let options_price = 0;
        $(".leasing-option-checkbox").each(function() {
            if (this.checked) {
                let price = parseFloat(this.dataset.price);
                let unit_type = this.dataset.unitType;

                if (unit_type === "0") {
                    let tempPrice = price * selected_months;
                    options_price += tempPrice;
                }

                else if (unit_type === "1") {
                    let tempPrice = price * selected_mileage;
                    options_price += tempPrice;
                }

                else if (unit_type === "2") {
                    options_price += price;
                }
                leasing_controller.leasing_options.push(this.dataset.id);
            }
        });
        if (this.debugg) {
            console.log("Selected extra options price: " + options_price);
        }
        return options_price;
    }

    // Calculate vehicle service costs
    calculateServiceCosts(mileage)
    {
        let service_costs = 0.025 * mileage;
        return Math.round(service_costs);
    }

    // Init variables when dom ready and connected
    initVariables()
    {
        const leasingController = this;
        let $slider = $('#car-selector-slider-leasing-time');
        this.slider = $slider.get(0);
        noUiSlider.create(this.slider, {
            start: [48],
            step: 1,
            connect: "lower",
            range: {
                'min': 6,
                'max': 72
            },
            format: wNumb({
                decimals: 0
            })
        });

        // Setting variables and calculating initial leasing price
        let $leasing_calculator = $('#leasing_calculator');
        leasingController.vehicle_price = parseInt($leasing_calculator.attr('data-vehicle-price'));
        leasingController.vehicle_mileage = parseInt($leasing_calculator.attr('data-vehicle-mileage')) * 1000;
        leasingController.vehicle_id = $leasing_calculator.attr('data-vehicle-id');
        leasingController.leasing_type = $leasing_calculator.attr('data-leasing_type');
        leasingController.leasing_price_selection = 0;
        leasingController.month_selection = 0;
        leasingController.mileage_selection = 0;
        leasingController.debugg = false;
        let mileage_choices = $('.button.outline.mileage');
        mileage_choices[0].classList.add("active");
        leasingController.selected_mileage = parseInt(mileage_choices[0].dataset.mileage) * 1000;

        //When leasing option change -> Calculate new leasing price
        $('.leasing-option-checkbox').change(function () {
            leasingController.calculateLeasingPrice();
        });

        //When slider values change -> Calculate new leasing price
        //Slider init trickers slider update function
        leasingController.slider.noUiSlider.on('update', function (values, handle) {
                leasingController.leasingtimeTarget.innerHTML = values + "kk";
                leasingController.calculateLeasingPrice();
        });

        //No need to call because slider init triggers update function
        //this.calculateLeasingPrice();
    }

    // Function for handling leasing offer button click
    createLeasingOffer(e)
    {
        e.preventDefault();
        let url = "/leasings/car_leasing";
        let options_length = this.leasing_options.length;
        let query_params = "?" + "car_nettix_id=" + this.vehicle_id + "&leasing_price="
            + this.leasing_price_selection + "&leasing_time="
            + this.leasing_month_selection + "&leasing_mileage=" + this.selected_mileage
            + "&leasing_type=" + this.leasing_type;
        if (options_length > 0) {
            query_params += "&leasing_option_settings_ids[]=";
            //Change for using array of object for getting the params
            for (let i = 0; i < options_length; i++) {
                query_params += this.leasing_options[i];
                query_params += ",";
            }
        }
        if (query_params[query_params.length -1 ] === ",") {
            query_params = query_params.slice(0, -1);
        }
        window.location = url + query_params
    }

    // Function for closing leasing calculator
    closeLeasingCalculator(e)
    {
        e.preventDefault();
        $('#leasing_calculator').slideToggle(600);
    }
}