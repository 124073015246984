// app/javascript/controllers/lead_controller.js
import { Controller } from "stimulus";
import Chart from "chart.js";
import { getData, postData, capitalizeFirstLetter, append_zero } from "./../helpers";

export default class extends Controller {
    static targets = ["startdate", "enddate", "errormsg", "datahead", "databody", "mychart"];
    users_key_value = [];
    random_color = ['(111,0,255)', '(152,98,60)', '(18,201,240)',
        '(46,216,186)', '(247,39,247)', '(1,1,1)', '(44,41,50)'];

    initialize() {
        this.errormsgTarget.style.display = 'none';
        this.startdateTarget.value = this.set_date(1, 'subtract');
        this.enddateTarget.value = this.set_date(0, 'add');
        this.submit();
    }

    submit(){
        let start_date = this.startdateTarget.value;
        let end_date = this.enddateTarget.value;

        postData("/admin/leads_dashboard/leads_by_user", 'POST', {start_date, end_date})
            .then(response => {
                let {funding_data, multiple} = response;
                let rows = "";
                if(multiple){
                    this.multiple_data_template(response);
                    this.createMainChart(funding_data);
                }else {
                    this.gen_th(["päivämäärä", "Liidit", "Kaupat", "Pull"]);
                    funding_data.map(fund => {
                        rows += this.single_data_template(fund);
                    });
                    this.databodyTarget.innerHTML = rows;
                }
            }).catch(error => console.log(error))
    }

    single_data_template(data){
        return `<tr>
            <td>${this.normalize_date(data.date)}</td>
            <td>
                <div>
                    Hakemukset: ${data.funding_leads}
                </div>
                <div>
                    Other: 
                </div>
            </td>
            <td></td>
            <td></td>
        </tr>`
    }

    multiple_data_template(data){
        let {funding_data} = data;
        let {users} = funding_data[0];
        let user_names = Object.keys(users);
        let name_nouns = this.normalize_names(user_names);
        let all_th_fiels = ["Päivämäärä", "Total leads", ...name_nouns];
        this.gen_th(all_th_fiels);
        let table_body = "";
        funding_data.map(item => {
            let rows = "<tr>";
            let tds = `<td>${this.normalize_date(item.date)}</td> <td>${item.funding_leads}</td>`;
            user_names.map(user => {
                tds += `<td>${item.users[user]}</td>`;
            });
            rows += tds + "</tr>";
            table_body += rows;
        });
        this.databodyTarget.innerHTML = table_body;

    }

    gen_th(items){
        let users = { ali_cise: 0, mari_meikalainen: 0, shakur_hassan: 0, ville_meikalainen: 0 };
        let users_array = Object.keys(users);

        let row = "";
        items.map(item => {
            row += `<th>${item}</th>`;
        });
        this.dataheadTarget.innerHTML = row;
    }

    createMainChart(fundings){
        let all_dates = [], all_fundings = [], all_funding_colors = [], all_funding_bg = [];
        let {users} = fundings[0];
        this.users_key_value = [];
        let users_keys = Object.keys(users);
        users_keys.map(key => {
            let obj = {};
            obj[key]= [];
            this.users_key_value.push(obj);
        });
        let ctx = this.mychartTarget;
        let mainChart = new Chart(ctx, {
            type: 'line',
            data: {},
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });

        fundings.map(funding => {
            let {date, funding_leads, users} = funding;
            this.users_key_value.map(kv => {
                let k = Object.keys(kv);
                kv[k].push(users[k])
            });
            all_fundings.push(funding_leads);
            all_funding_bg.push('rgba(255, 99, 132, 0)');
            all_funding_colors.push('rgba(255, 0, 0, 1)');
            mainChart.data.labels.push(date);

        });
        mainChart.data.datasets.push({
            label: 'Total fundings',
            data: all_fundings,
            backgroundColor: all_funding_bg,
            borderColor: all_funding_colors,
            borderWidth: 1
        });

        this.users_key_value.map((obj, index) => {
            let key = Object.keys(obj);
            let name = this.normalize_single_name(key[0]);
            let all_data = obj[key[0]];
            let bg_color = `rgba(${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, ${Math.floor(Math.random() * 255)}, 1)`;
            let bg_colors_array = [];
            all_data.map(ad => {
                bg_colors_array.push(bg_color)
            });
            mainChart.data.datasets.push({
                label: name,
                data: all_data,
                backgroundColor: ['rgba(255, 99, 132, 0)'],
                borderColor: bg_colors_array,
                borderWidth: 1
            });
        });
        mainChart.update();
    }

    normalize_names(names){
        let modified_names = [];
        names.map(name => {
            let new_name = name.split("_").map(n => capitalizeFirstLetter(n)).join(" ");
            modified_names.push(new_name)
        });
        return modified_names;
    }

    normalize_single_name(name){
        return name.split("_").map(n => capitalizeFirstLetter(n)).join(" ");
    }

    getRandomColor() {
        let letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    get_obj_size(obj){
        let size = 0;
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    }

    set_date(days, action){
        let d = new Date();
        let new_d = d;
        if(action === 'add'){
            new_d = new Date(d.setDate(d.getDate() + days));
        }else if(action === 'subtract'){
            new_d = new Date(d.setDate(d.getDate() - days));
        }
        let full_year = new_d.getFullYear();
        let month = append_zero(new_d.getMonth() + 1);
        let day = append_zero(new_d.getDate());
        return full_year + "-" + month + "-" + day
    }

    normalize_date(date){
        let new_date = new Date(date);
        let day = append_zero(new_date.getDate());
        let month = append_zero(new_date.getMonth() + 1);
        let year = new_date.getFullYear()

        return day + "." + month + "." + year;
    }
}
