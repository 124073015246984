// app/javascript/controllers/leasing_car_images_controller.js

import {Controller} from "stimulus"

export default class extends Controller
{
    static get targets()
    {
        return [""];
    }

    connect()
    {
        this.initVariables();
    }

    //Init image slider
    initVariables()
    {
        $("#lightSlider").lightSlider({
            gallery: true,
            item: 1,
            loop: true,
            thumbItem: 9,
            slideMargin: 0,
            enableDrag: false,
            currentPagerPosition: "center",
            controls: true,
            prevHtml:
                "<span class='arrows'><span class='arrows-inner'>&#8249;</span></span>",
            nextHtml:
                "<span class='arrows'><span class='arrows-inner'>&#8250;</span></span>",

            onSliderLoad: function (el) {
                //el.lightGallery({selector: '#imageGallery .lslide'})
            }
        });
    }
}
