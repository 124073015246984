// app/javascript/controllers/leasing_menu_controller.js

import {Controller} from "stimulus"

export default class extends Controller
{
    static get targets()
    {
        return [""];
    }

    connect() {}

    // Show leasing calculator if 'jätä tarjous' button is clicked
    showLeasingCalculator(e)
    {
        e.preventDefault();
        $('#leasing_calculator').slideToggle(600, function () {
            if ($(this).is(":visible")) { //Check to see if element is visible then scroll to it
                $('html,body').animate({ // Animate the scroll
                    scrollTop: $('#leasing_calculator').offset().top - 25 // The - 25 is to stop the scroll 25px above the element
                }, 600).top;
            }
        });
    }
}
