// app/javascript/controllers/mobile_menu_controller.js

// noinspection ES6CheckImport
import {Controller} from "stimulus"

// noinspection JSUnusedGlobalSymbols
export default class extends Controller
{
    static get targets()
    {
        return [""];
    }

    connect()
    {
        $('#mobile-menu-toggle').on('click',function() {
            $(this).css('display','none');
            $('#mobile-nav').css('display','block');
        });
    }

    // noinspection JSUnusedGlobalSymbols
    closeMobileMenu(e)
    {
        e.preventDefault();
        $('#mobile-nav').css('display','none');
        $('#mobile-menu-toggle').css('display','block');
    }
}
