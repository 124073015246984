// app/javascript/controllers/dashboard_controller.js
import { Controller } from "stimulus";
import {append_zero, getData} from "./../helpers";

export default class extends Controller {
    static targets = ["datasummary"];

    initialize() {
        this.getSummary()
    }

    getSummary(){
        getData("/admin/leads_dashboard/total_summary")
            .then(response => {
                let {today, week, last_month, current_month} = response;
                this.summayChart(today, week, last_month, current_month);
            })
            .catch(error => console.log(error))
    }

    summayChart(daily, weekly, last_month, current_month){
        let ctx = this.datasummaryTarget;
        let last_month_name = this.getMonthName(new Date(), 1);
        let current_month_name = this.getMonthName(new Date(), 0);
        let {changes, contacts, fundings, nettiauto, sales} = daily;
        let {changes_w, contacts_w, fundings_w, nettiauto_w, sales_w} = weekly;
        let {changes_m, contacts_m, fundings_m, nettiauto_m, sales_m} = last_month;
        let {changes_cm, contacts_cm, fundings_cm, nettiauto_cm, sales_cm} = current_month;
        let myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Today', 'Last 7 days', last_month_name, current_month_name],
                datasets: [{
                    label: 'Hakemukset',
                    data: [fundings, fundings_w, fundings_m, fundings_cm],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 99, 132, 0.2)',
                        'rgba(255, 99, 132, 0.2)'
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                        'rgba(255, 99, 132, 1)',
                    ],
                    borderWidth: 1
                },
                    {
                        label: 'Myynnit',
                        data: [sales, sales_w, sales_m, sales_cm],
                        backgroundColor: [
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(54, 162, 235, 0.2)'
                        ],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(54, 162, 235, 1)'
                        ],
                        borderWidth: 1
                    },
                    {
                        label: 'Vaihdot',
                        data: [changes, changes_w, changes_m, changes_cm],
                        backgroundColor: [
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 206, 86, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(255, 206, 86, 1)',
                        ],
                        borderWidth: 1
                    },
                    {
                        label: 'Yhteydenotot',
                        data: [contacts, contacts_w, contacts_m, contacts_cm],
                        backgroundColor: [
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                        ],
                        borderColor: [
                            'rgba(75, 192, 192, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(75, 192, 192, 1)',
                        ],
                        borderWidth: 1
                    },
                    {
                        label: 'Nettiauto linkit',
                        data: [nettiauto, nettiauto_w, nettiauto_m, nettiauto_cm],
                        backgroundColor: [
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                        ],
                        borderColor: [
                            'rgba(153, 102, 255, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(153, 102, 255, 1)',
                        ],
                        borderWidth: 1
                    }]
            },
            options: {
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }
            }
        });
    }

    getMonthName(from_date, num){
        let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        let new_d = new Date(from_date.setMonth(from_date.getMonth() - num));
        let month = new_d.getMonth();
        return months[month];
    }
}
