export function getMetaValue(name) {
    const element = findElement(document.head, `meta[name="${name}"]`);
    if (element) {
        return element.getAttribute("content");
    }
}

export function findElement(root, selector) {
    if (typeof root == "string") {
        selector = root;
        root = document;
    }
    return root.querySelector(selector);
}

export function toArray(value) {
    if (Array.isArray(value)) {
        return value;
    } else if (Array.from) {
        return Array.from(value);
    } else {
        return [].slice.call(value);
    }
}

export function removeElement(el) {
    if (el && el.parentNode) {
        el.parentNode.removeChild(el);
    }
}

export function insertAfter(el, referenceNode) {
    return referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
}

export async function postData(url = "", method, data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            "Content-Type": "application/json",
            'X-CSRF-TOKEN': document
                .querySelector("meta[name='csrf-token']")
                .getAttribute("content")
        },
        //redirect: 'follow', // manual, *follow, error
        //referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data), // body data type must match "Content-Type" header
    });
    if(!response.ok){
        if(response.status === 422){
            return response.json();
        }
        throw new Error(`HttpError: ${response.status} ${response.statusText}`)
    }
    return response.json(); // parses JSON response into native JavaScript objects
}

export async function getData(url = "") {
    const response = await fetch(url, {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
            "Action": "application/json"
        },
    });
    return response.json(); // parses JSON response into native JavaScript objects
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function append_zero(value){
    return value < 10 ? `0${value}` : value
}