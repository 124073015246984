// app/javascript/controllers/carlist_controller.js

window.lightSlider = require("lightslider");
window.noUiSlider = require("nouislider");
window.wNumb = require("wnumb");
// noinspection ES6CheckImport
import { Controller } from "stimulus"

// noinspection JSUnusedGlobalSymbols
export default class extends Controller
{
    static get targets()
    {
        return ["search", "carMake", "carModel", "lowPrice", "highPrice", "lowMileage", "highMileage",
            "lowYear", "highYear", "transmission", "bodyType", "driveType", "fuelType", "order"];
    }

    connect()
    {
        const controller = this;

        $.ajax({
            type: 'GET',
            dataType: "json",
            url: '/cars/slider_values',
            data: null,
            success: function(response) {
                let values = response["values"];
                controller.initPriceSlider(values[0], values[1]);
                controller.initMileageSlider(values[2], values[3]);
                controller.initYearSlider(values[4], values[5]);
                controller.initButtons();
            }
        });
    }

    search()
    {
        this.refreshData(0)
    }

    nextPage()
    {
        let page = $('.pagination .current').last().text();
        if (page !== '') {
            this.refreshData(parseFloat(page) + 1)
        }
    }

    refreshData(page)
    {
        let url = new URL(this.data.get("url"), window.location.origin);
        url.search = new URLSearchParams(this.getParams(page));

        fetch(url)
            .then(response => response.text())
            .then(html => {
                if (page === 0) {
                    $("#car-selector-results").html(html);
                } else {
                    $("#car-selector-results").append(html);
                }
                // Hide 'Next page' if last page visible
                let split = html.split("---Pages: ");
                if (split.length === 2) {
                    let pages = parseFloat(split[1].substring(0, 10));
                    //console.log("PAGE: " + page + ", PAGES: " + pages);
                    if (page >= pages || pages < 1.0) {
                        $('#next_page').addClass('hidden')
                    } else {
                        $('#next_page').removeClass('hidden')
                    }
                }
            });
    }

    getParams(page)
    {
        // Read targets
        return {
            search_text: this.searchTarget.value,
            car_make: this.carMakeTarget.value,
            car_model: this.carModelTarget.value,
            price_low: this.lowPriceTarget.innerText,
            price_high: this.highPriceTarget.innerText,
            year_low: this.lowYearTarget.innerText,
            year_high: this.highYearTarget.innerText,
            mileage_low: this.lowMileageTarget.innerText,
            mileage_high: this.highMileageTarget.innerText,
            transmission: this.transmissionTarget.value,
            body_type: this.bodyTypeTarget.value,
            drive_type: this.driveTypeTarget.value,
            fuel_type: this.fuelTypeTarget.value,
            order: this.orderTarget.value,
            tow_bar: $('#accessories_tow_bar').hasClass("accessories_active"),
            air_condition: $('#accessories_air_condition').hasClass("accessories_active"),
            cruise_control: $('#accessories_cruise_control').hasClass("accessories_active"),
            two_wheels: $('#accessories_two_wheels').hasClass("accessories_active"),
            page: page
        }
    }

    initButtons()
    {
        const controller = this;
        let $makeSelector = $('#car-selector-make');
        let $modelSelector = $('#car-selector-model');

        $makeSelector.val(function() {
            $.ajax({
                type: 'GET',
                dataType: "json",
                url: '/cars/makes_list',
                data: null,
                success: function(response) {
                    let makes = response["makes"];
                    $makeSelector.empty();
                    $makeSelector.append('<option value="">' + window.localized_make_option_0 + '</option>');
                    for (var i = 0; i < makes.length; ++i) {
                        $makeSelector.append('<option value="' + makes[i] + '">' + makes[i] + '</option>');
                    }
                }
            });
        });

        $makeSelector.on('change', function(e) {
            let selected = $(this).val();
            $.ajax({
                type: 'GET',
                dataType: "json",
                url: '/cars/models_list',
                data: { make: selected },
                success: function(response) {
                    let models = response["models"];
                    $modelSelector.empty();
                    if (models.length === 0) {
                        $modelSelector.append('<option value="">' + window.localized_model_option_0 + '</option>');
                        $modelSelector.val('');
                    } else {
                        $modelSelector.append('<option value="">' + window.localized_model_option_1 + '</option>');
                        for (var i = 0; i < models.length; ++i) {
                            $modelSelector.append('<option value="' + models[i] + '">' + models[i] + '</option>');
                        }
                    }
                    controller.refreshData(0);
                }
            });
        });

        $('#detailed_search_show').on('click', function(e) {
            e.preventDefault();
            $("#detailed_search").show();
            $("#detailed_search_show").hide();
            $("#detailed_search_hide").show();
            $("#simple_search").removeClass("simple_search_margin")
        });

        $('#detailed_search_hide').on('click', function(e) {
            e.preventDefault();
            $("#detailed_search").hide();
            $("#detailed_search_show").show();
            $("#detailed_search_hide").hide();
            $("#simple_search").addClass("simple_search_margin")
        });

        $('#accessories_tow_bar').on('click', function() {
            $(this).toggleClass("accessories_active");
            controller.refreshData(0);
        });

        $('#accessories_air_condition').on('click', function() {
            $(this).toggleClass("accessories_active");
            controller.refreshData(0);
        });

        $('#accessories_cruise_control').on('click', function() {
            $(this).toggleClass("accessories_active");
            controller.refreshData(0);
        });

        $('#accessories_two_wheels').on('click', function() {
            $(this).toggleClass("accessories_active");
            controller.refreshData(0);
        });
    }

    initPriceSlider(minPrice, maxPrice)
    {
        const controller = this;
        let $priceSlider = $('#car-selector-slider-price');

        if ($priceSlider.length === 1) {
            let slider = $priceSlider.get(0);
            if (typeof(slider.noUiSlider) != 'undefined') {
                return;
            }

            noUiSlider.create(slider, {
                start: [minPrice, maxPrice],
                connect: true,
                step: 500,
                range: {
                    'min': minPrice,
                    'max': maxPrice
                },
                format: wNumb({
                    decimals: 0,
                    thousand: ' ',
                    suffix: ' &euro;'
                }),
                pips: null
            });

            let snapValues = [
                controller.lowPriceTarget,
                controller.highPriceTarget];

            slider.noUiSlider.on('update', function(values, handle) {
                snapValues[handle].innerHTML = values[handle];
                controller.refreshData(0);
            });
        }
    }

    initMileageSlider(minMileage, maxMileage)
    {
        const controller = this;
        let $mileageSlider = $('#car-selector-slider-mileage');

        if ($mileageSlider.length === 1) {
            let slider = $mileageSlider.get(0);
            if (typeof(slider.noUiSlider) != 'undefined') {
                return;
            }

            noUiSlider.create(slider, {
                start: [minMileage, maxMileage],
                connect: true,
                step: 5000,
                range: {
                    'min': minMileage,
                    'max': maxMileage
                },
                format: wNumb({
                    decimals: 0,
                    thousand: ' '
                }),
                pips: null
            });

            let snapValues = [
                controller.lowMileageTarget,
                controller.highMileageTarget];
            slider.noUiSlider.on( 'update', function( values, handle ) {
                snapValues[ handle ].innerHTML = values[ handle ];
                controller.refreshData(0);
            });
        }
    }

    initYearSlider(minYear, maxYear)
    {
        const controller = this;
        let $yearSlider = $('#car-selector-slider-year');

        if ($yearSlider.length === 1) {
            let slider = $yearSlider.get(0);
            if (typeof(slider.noUiSlider) != 'undefined') {
                return;
            }

            noUiSlider.create(slider, {
                start: [minYear, maxYear],
                connect: true,
                step: 1,
                range: {
                    'min': minYear,
                    'max':maxYear
                },
                format: wNumb({
                    decimals: 0
                }),
                pips: null
            });

            this.lowYearTarget.innerText = minYear;
            this.highYearTarget.innerText = maxYear;

            let snapValues = [
                controller.lowYearTarget,
                controller.highYearTarget];

            slider.noUiSlider.on('update', function( values, handle) {
                snapValues[handle].innerHTML = values[handle];
                controller.refreshData(0);
            });
        }
    }
}